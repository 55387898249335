* {
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #f5f6fa;
}

.app {
  display: flex;
  justify-content: space-evenly;
  background-color: #f5f6fa;
  padding: 20px;
}


.app__dropdown {
  background-color: white;
}

.app__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.app__header > h1 {
  color: #fc3c3c;
  font-size: 2rem;
}

.app__stats {
  display: flex;
  justify-content: space-between;
}

.app__left {
  flex: 0.9;
}

.app__right {
  display: flex;
  flex-direction: column;
}

.app__right .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app__graph {
  flex-grow: 1;
}

.app__graphTitle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.app__information > h3 {
  color: #6a5d5d;
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.app__information > h3:last-of-type {
  margin-top: 1rem;
}

@media (max-width: 990px) {
  .app {
    flex-direction: column;
  }

  .app__left {
    margin-bottom: 1rem;
  }

}

.footer {
  text-align: center;
  padding-top: 20px;
}

.footer__text__heart {
  color: red;
}

.footer__text__link {
  text-decoration: none;
  color: blue
}


.info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 990px) {
  .info {
    flex-direction: column;
    align-items: center;
  }
}
